import useWebSocketWithToken from "./use-websocket-with-token";
import {WS_BASE_URL} from "../constants/api-url";
import {HOME, SUMMARY} from "../constants/routes";
import {useNavigate} from "react-router-dom";
import {useCallback, useContext} from "react";
import AppContext from "../app-context";
import {Report} from "../models/report";
import {getJwtToken} from "../services/headers";

const useWebSocket = () => {
    const navigate = useNavigate();
    const { setReport } = useContext(AppContext);

    const onMessage = useCallback((message: MessageEvent<any>) => {
        const data = JSON.parse(message.data);
        if (data.unpair) {
            localStorage.removeItem("jwt");
            window.location.reload();
        } else if (data.token) {
            localStorage.setItem("jwt", data.token);
            navigate(HOME)
        } else {
            try {
                setReport(data as Partial<Report>);
                navigate(SUMMARY);
            } catch (error) {
                console.error(error);
            }
        }
    }, [navigate, setReport])

    const { closeConnection } = useWebSocketWithToken(
        WS_BASE_URL!,
        getJwtToken,
        {
            onError: (error) => console.error('WebSocket Error:', error),
            onMessage,
            reconnectInterval: 5000,
        }
    );

    window.addEventListener("beforeunload", () => {
        closeConnection();
    });

    return { closeConnection };
}

export default useWebSocket;