import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

import Car from "./car";
import Label from "./label";
import Loader from "./loader";
import Navbar from "./navbar";
import AppContext from "../app-context";
import { HOME } from "../constants/routes";
import useTranslations from "../hooks/use-translations";
import {Civility} from "../constants";
import {DamageSeverity} from "../models/enums/damage-severity";

const Summary = () => {
  const navigate = useNavigate();
  const { isTranslationsLoading, report } = useContext(AppContext);
  useTranslations(report?.customerInfo?.language);

  const { companyName, civility, firstName, lastName } = report.customerInfo ?? {};
  let displayName = companyName;

  if (civility === Civility.MR || civility === Civility.MS) {
    if (firstName && firstName?.length > 0 && lastName && lastName?.length > 0) {
      displayName = `${firstName} ${lastName}`;
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(`/${HOME}`);
    }, 60000); // Time will increase, 1 minute for debug

    return () => clearTimeout(timeoutId);
  }, [navigate, report]);

  return isTranslationsLoading ? (
    <Loader />
  ) : (
    <div className="flex flex-col h-screen overflow-hidden bg-defaultGrey">
      <Navbar />
      <div className="flex h-screen gap-8 p-8">
        <div
          className={classNames(
            "flex flex-col justify-around items-center rounded-small bg-white w-1/3 p-8 gap-8",
            { "w-full": !report.tires }
          )}
        >
          <div className="flex flex-col gap-4">
            {Boolean(displayName) && (
              <Label
                title={
                  <FormattedMessage id="customer" defaultMessage="Customer" />
                }
                content={displayName}
              />
            )}
            <Label
              title={
                <FormattedMessage
                  id="vehicleRegistration"
                  defaultMessage="Vehicle Registration"
                />
              }
              content={report?.registration?.toUpperCase()}
            />
          </div>
          {report?.damages && (
            <>
              <div className="w-full h-0.5 tv:h-1 bg-lightGrey" />
              <div className="flex flex-col gap-4">
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.low"
                      defaultMessage="Low severity damages"
                    />
                  }
                  content={report.damages[DamageSeverity.NONE]}
                />
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.medium"
                      defaultMessage="Medium severity damages"
                    />
                  }
                  content={report.damages[DamageSeverity.LOW]}
                />
                <Label
                  title={
                    <FormattedMessage
                      id="bodyState.high"
                      defaultMessage="High severity damages"
                    />
                  }
                  content={report.damages[DamageSeverity.HIGH]}
                />
              </div>
            </>
          )}
        </div>
        {report?.tires && (
          <div className="flex w-2/3 p-8 rounded-small bg-white">
            <Car tires={report.tires} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
