import { useEffect, useState, useCallback } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

interface UseWebSocketWithTokenOptions {
    onOpen?: () => void;
    onClose?: () => void;
    onError?: (error: Event) => void;
    onMessage?: (message: MessageEvent) => void;
    reconnectInterval?: number;
}

interface UseWebSocketWithTokenResult {
    isConnected: boolean;
    closeConnection: () => void;
}

/**
 * Custom hook that connects to a WebSocket URL with token handling and
 * event listeners using `react-use-websocket`.
 * @param baseUrl - The WebSocket base URL
 * @param getToken - A function to asynchronously retrieve the JWT token
 * @param options - Event listeners and optional reconnect settings
 */
const useWebSocketWithToken = (
    baseUrl: string,
    getToken: () => Promise<string | null>,
    options: UseWebSocketWithTokenOptions = {}
): UseWebSocketWithTokenResult => {
    const { onOpen, onClose, onError, onMessage, reconnectInterval = 5000 } = options;

    const [socketUrl, setSocketUrl] = useState<string | null>(null); // State to hold the WebSocket URL with token

    useEffect(() => {
        // Fetch the token and set the WebSocket URL
        const fetchTokenAndSetUrl = async () => {
            const token = await getToken();
            if (token) {
                setSocketUrl(`${baseUrl}?token=${token}`);
            } else {
                console.error('Token not available');
            }
        };
        fetchTokenAndSetUrl();
    }, [baseUrl, getToken]);

    // Connect to WebSocket only when socketUrl is set
    const {
        readyState,
        getWebSocket,
        lastMessage,
    } = useWebSocket(socketUrl, {
        shouldReconnect: () => true,
        reconnectAttempts: 10,
        reconnectInterval,
        onOpen: () => onOpen && onOpen(),
        onClose: () => onClose && onClose(),
        onError: (event) => onError && onError(event),
    });

    // Handle incoming messages
    useEffect(() => {
        if (lastMessage) {
            onMessage?.(lastMessage);
        }
    }, [lastMessage, onMessage]);

    // Close WebSocket connection
    const closeConnection = useCallback(() => {
        const ws = getWebSocket();
        if (ws) ws.close();
    }, [getWebSocket]);

    // WebSocket connection status
    const isConnected = readyState === ReadyState.OPEN;

    return {
        isConnected,
        closeConnection,
    };
};

export default useWebSocketWithToken;
